import React , { useEffect } from "react";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import banner from "./blogimg/the-biggest-eyewear-trends-of-2021/banner.png";
import blog01 from "./blogimg/the-biggest-eyewear-trends-of-2021/blog-01.jpg";
import blog02 from "./blogimg/the-biggest-eyewear-trends-of-2021/blog-02.jpg";
import blog03 from "./blogimg/the-biggest-eyewear-trends-of-2021/blog-03.jpg";
import blog04 from "./blogimg/the-biggest-eyewear-trends-of-2021/blog-04.jpg";
import blog05 from "./blogimg/the-biggest-eyewear-trends-of-2021/blog-05.jpg";
import blog06 from "./blogimg/the-biggest-eyewear-trends-of-2021/blog-06.jpg";
import blog07 from "./blogimg/the-biggest-eyewear-trends-of-2021/blog-07.jpg";
import blog08 from "./blogimg/the-biggest-eyewear-trends-of-2021/blog-08.jpg";

//AOS
import AOS from "aos";
import "aos/dist/aos.css";

export default function Blog01() {
  useEffect(() => {

    AOS.init({
      
    });
    
  }, []);

  useEffect(() => {

    if(AOS){
      AOS.refresh();
    }
    
  });


  return (
    <div>
      <Helmet>
        <title>THE BIGGEST EYEWEAR TRENDS OF 2021</title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta name="title" content="OiC" />
        <meta name="description" content="OiC Apps Website" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta property="og:description" content="OiC Apps Website" />
      </Helmet>
      <Layout>
        <div
          className="bannerImage"
          style={{
            background: "#2B2B2B",
          }}
        >
          <div className="padding60">
            <section className="container-fluid ">
              <div className="row aligner">
                <div className="col-md-1"></div>
                <div className="col-md-5">
                  <h1>
                    THE BIGGEST{" "}
                    <span>
                      EYEWEAR TRENDS OF{" "}
                      <span style={{ color: "#FF9810" }}>2021</span>
                    </span>
                  </h1>
                </div>

                <div className="col-md-6 noPadding">
                  <img
                    src={banner}
                    alt="THE BIGGEST EYEWEAR TRENDS OF 2021"
                    className="img-responsive"
                  ></img>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="padding60">
          <div className="container-fluid">
            <div className="row" data-aos="fade-left" data-aos-duration="400">
              <div className="col-md-2"></div>
              <div className="col-md-1">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/eye-glass.png"
                  width="120"
                ></img>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <p style={{ lineHeight: "2" }}>
                  The good news is that 2021 is here, even if we are greeted by
                  new lockdowns (along with cold, overcast weather). After
                  experimenting with Work From Home glasses throughout the year,
                  it's safe to predict that the 2021 collection will be a riot
                  of both fresh, experimental statements and timeless classics.
                </p>
              </div>
              <div className="col-md-3">
                <p style={{ lineHeight: "2" }}>
                  To find the ideal pair of glasses, keep these simple
                  guidelines in mind:
                </p>
                <ul className="CustomList">
                  <li>The shape of your face</li>
                  <li>Your individuality</li>
                  <li>The colour of your skin</li>
                  <li>Your preferred way of life</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="container-fluid">
            <div className="row" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <h2>
                  Here Are Some Of The Most Popular Eyeglasses Styles That Will
                  Be Popular In The Coming Year:
                </h2>
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <img src={blog01} className="img-responsive"></img>
              </div>
              <div className="col-md-1"></div>
            </div>
            <br></br>
            <div className="row" data-aos="fade-left" data-aos-duration="400">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <h2
                  style={{
                    borderBottom: ".5px solid #707070",
                    paddingBottom: "20px",
                    marginBottom: "20px",
                  }}
                >
                  Oversized Frames : Style and Protection
                </h2>
                <p>
                  The pandemic has strongly influenced this year's glasses
                  trends, as evidenced by an unbelievable variety of over-sized
                  frames. According to some experts, we are seeing more
                  large-sized frames that cover our eyes and provide protection
                  against the virus. Trends in eyewear are gradually moving away
                  from micro, mid-90s frames and toward large face-shielding
                  options. The retro "granddaddy glasses," on the other hand,
                  are back in style.
                </p>
                <br></br>
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6" data-aos="fade-right" data-aos-duration="400">
                      <img src={blog02} className="img-responsive"></img>
                      <br></br>
                      <br></br>
                      <h2
                        style={{
                          borderBottom: ".5px solid #707070",
                          paddingBottom: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        Thin Rim Frames : For Understated Elegance
                      </h2>
                      <p>
                        Glasses with a thin rim are popular. Nothing beats a
                        pair of sleek gold or rose-gold rimmed metal frames for
                        style. You won't look anything less than an Instagram
                        influencer if you pair them with simple plain colours.
                      </p>
                    </div>
                    <div className="col-md-6" data-aos="fade-left" data-aos-duration="400">
                      <img src={blog03} className="img-responsive"></img>{" "}
                      <br></br>
                      <br></br>
                      <h2
                        style={{
                          borderBottom: ".5px solid #707070",
                          paddingBottom: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        Round Glasses : Not Just For The Wizards
                      </h2>
                      <p>
                        Though round glasses have been popular for several
                        years, this season's focus is on metal-rimmed round
                        frames from the 1980s and 1990s. Try these round frame
                        high-nose bridge glasses because nothing beats round
                        glasses in muted colours for a casual but elegant look.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>

            <div className="row" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <img src={blog04} className="img-responsive"></img>
              </div>
              <div className="col-md-1"></div>
            </div>
            <br></br>
            <div className="row" data-aos="fade-left" data-aos-duration="400">
              <div className="col-md-1"></div>
              <div className="col-md-10" style={{ padding: "0 30px" }}>
                <h2
                  style={{
                    borderBottom: ".5px solid #707070",
                    paddingBottom: "20px",
                    marginBottom: "20px",
                  }}
                >
                  Wooden Frames: For All Things Natural
                </h2>
                <p>
                These styles were already in the eyewear collection, but they were never on the list of hot trends. Wooden glasses, on the other hand, are ready to fill all of the optical stores around, with tweaked designs that include more wearable options and added colours. Pair with your funkiest outfits and ace the 2021 glasses trend.
                </p>
                <br></br>
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6" data-aos="fade-right" data-aos-duration="400">
                      <img src={blog05} className="img-responsive"></img>
                      <br></br>
                      <br></br>
                      <h2
                        style={{
                          borderBottom: ".5px solid #707070",
                          paddingBottom: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        Transparent Rims : Easy-To-Wear Glamour

                      </h2>
                      <p>
                      One of the eyewear styles from last year that has again made its place in the hot trends of the season (and why not) is the clear, transparent frames style. With added glamour of subtle pastel tints like light blue, pink, green, peach and grey, and unique designs like half-metal and half-transparent, transparent glasses are here to stay for many more seasons.

                      </p>
                    </div>
                    <div className="col-md-6" data-aos="fade-left" data-aos-duration="400">
                      <img src={blog06} className="img-responsive"></img>{" "}
                      <br></br>
                      <br></br>
                      <h2
                        style={{
                          borderBottom: ".5px solid #707070",
                          paddingBottom: "20px",
                          marginBottom: "20px",
                        }}
                      >
                       Geometric Glasses : No Hard Maths
                      </h2>
                      <p>
                      Ditching those micro frames that were trending last year, the eyewear market has presented more wearable options, though with added funk. Geometric glasses like octagon, hexagon, square and rectangle are excellent to highlight or accentuate your facial features, especially if you have a round face. Look at these grey lilac octagon glasses, perfect for both party night-overs and professional meet-ups.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                  <br></br><br></br>
                <div className="container-fluid">
                  <div className="row aligner">
                    <div className="col-md-6">
                      <img src={blog07} className="img-responsive"></img>
                    
                    </div>
                    <div className="col-md-6">
                
                      <h2
                        style={{
                          borderBottom: ".5px solid #707070",
                          paddingBottom: "20px",
                          marginBottom: "20px",
                        }}
                      >
                      Cat-Eye Glasses: All The Bold Ladies?
                      </h2>
                      <p>
                      The beautiful cat-eye glasses with exaggerated rims, classic tortoise or bright colours on a crystalline body are sure to make a statement. You have a frame for every game.
                      </p>
                    </div>
                  </div>
                </div>
                <br></br>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
          
          <div className="row" data-aos="fade-left" data-aos-duration="400">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <img src={blog08} className="img-responsive"></img>
              </div>
              <div className="col-md-1"></div>
            </div>
            <br></br>
            
            <div className="row" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-1"></div>
              <div className="col-md-10">
              <div className="container-fluid">
                  <div className="row">
                  <div className="col-md-12">
                  <h2
                        style={{
                          borderBottom: ".5px solid #707070",
                          paddingBottom: "20px",
                          marginBottom: "20px",
                        }}
                      >
                     Blue Light Reading Lenses
                      </h2>
                    </div>
                    <div className="col-md-6">
                    <p>We've all spent far more time on screens than our eyes can handle as a result of the lockdown. The spectrum of light includes blue light rays. They move at a high rate, causing damage to human eyes such as cataracts, macular degeneration, and Dry Eye Syndrome. We have had a hard time struggling with dry, irritated eyes.
</p>
                    </div>
                    <div className="col-md-6">
                     
                      <p>
                      It was fortunately not too late to experiment with blue light blocking glasses. As more people become aware of the negative effects of blue light, anti-blue light glasses will become more popular in 2021.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
            <br></br>
        </div>
      </Layout>
    </div>
  );
}
